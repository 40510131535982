<template>
  <div v-if="isLoaded">
    <sgv-form
      :method.sync="method"
      :options="options">

      <DocConfigDetailNetPriceForm
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :formData="formData"
        :method="method"
        :v="$v">
      </DocConfigDetailNetPriceForm>
    </sgv-form>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DocConfigDetailNetPriceForm from './DocConfigDetailNetPriceForm'
import {
  DETAIL_DOC_CONFIG_NET_PRICE,
  CREATE_DOC_CONFIG_NET_PRICE,
  DESTROY_DOC_CONFIG_NET_PRICE,
} from './graph'
import { required } from 'vuelidate/lib/validators'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.title
    }
  },
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}DocConfigListNetPrice`,
      formData: {
        name: '',
        accountId: null,
        inventoryId: null,
      },
      isLoaded: false,
    }
  },
  validations: {
    formData: {
      name: {required},
      accountId: {required},
      inventoryId: {required},
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:config`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:config`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC_CONFIG_NET_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.item)
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return {
        name: v.name,
        accountId: v.accountId,
        inventoryId: v.inventoryId,
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_NET_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docConfigId: res.data.item.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_NET_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_NET_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    }
  },
  created () {
    if (this.docConfigId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
  },
  components: {
    DocConfigDetailNetPriceForm
  }
}
</script>

<style lang="css">
</style>
